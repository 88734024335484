export default async function CompaniesMap(el, {
    markerHandle,
    activeClass = 'is-active',
    locations = [],
}) {
    /* eslint-disable no-undef */
    const { LatLngBounds } = await google.maps.importLibrary('core');
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    /* eslint-enable no-undef */

    const mapCont = el.querySelector('nav + div');
    const locationsListing = el.querySelectorAll('nav > ul > li');
    const [lats, lngs] = locations
        .reduce((acc, { lat, lng }) => [acc[0] + lat, acc[1] + lng], [0, 0]);
    const center = locations.length
        ? { lat: lats / locations.length, lng: lngs / locations.length }
        : { lat: 35.2271, lng: -80.8431 };
    const bounds = new LatLngBounds(center);
    const map = new Map(mapCont, {
        mapId: 'a48f52d8b2ae6622',
        zoom: locations.length === 1 ? 15 : 6,
        center,
        disableDefaultUI: true,
        zoomControl: true,
    });
    const openMarker = marker => {
        marker.content.classList.add(activeClass);
        marker.zIndex = 1;
        map.panTo(marker.position);
    };
    const closeMarker = marker => {
        marker.content.classList.remove(activeClass);
        marker.zIndex = null;
    };
    const markers = locations
        .filter((_, i) => locationsListing[i])
        .map(({ title, lat, lng }, i) => {
            const content = locationsListing[i].querySelector(markerHandle);
            const close = content.querySelector('button');
            const position = { lat, lng };

            if (locations.length > 1) {
                bounds.extend(position);
            }

            close.onclick = e => {
                e.stopPropagation();
                closeMarker(markers[i]);
            };

            return new AdvancedMarkerElement({
                map,
                position,
                title,
                content,
            });
        });

    document.addEventListener('keyup', ({ key }) => {
        if (key === 'Escape') {
            markers.forEach(m => { closeMarker(m); });
            locationsListing.forEach(l => { l.classList.remove(activeClass); });
        }
    });
    locationsListing.forEach((l, i) => {
        l.onmouseenter = () => {
            markers.forEach(m => { closeMarker(m); });
            locationsListing.forEach((ll, ii) => { ll.classList.toggle(activeClass, i === ii); });
            openMarker(markers[i]);
        };
        l.onmouseleave = () => {
            markers.forEach(m => { closeMarker(m); });
            locationsListing.forEach((ll, ii) => { ll.classList.toggle(activeClass, i === ii); });
        };
    });
    markers.forEach((m, i) => {
        m.addListener('click', () => {
            if (m.content.classList.contains(activeClass)) {
                const { href } = m.content.querySelector('a');

                if (href) {
                    window.open(href, '_blank');
                }
            }
            markers.forEach(mm => { closeMarker(mm); });
            locationsListing.forEach((l, ii) => { l.classList.toggle(activeClass, i === ii); });
            openMarker(m);
        });
    });

    if (locations.length > 1) {
        map.fitBounds(bounds);
    }
}
